<template>
	<div class="app-container interviewInfo applyApprovalInfo" v-loading="loading">
		<div class="titlebox">
			<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
			<div class="title">{{ type === 'againApply' ? '再入职申请审批详情' : type == 1 ? '审批详情' : '申请入职' }}</div>
		</div>
		<div class="picbox">
			<div style="margin-right: 10px">
				<div
					class="picbox_img"
					style="
						background: url('https://ehr-prod.oss-cn-shenzhen.aliyuncs.com/template/878b8e2202ca46bdbc479c7ebe1cd460.png') no-repeat;
						background-size: cover;
						background-position: center;
					"
				></div>
			</div>
			<div>
				<h2>
					{{ type == 'againApply' ? approvalDetail2.name : type ? approvalDetail3.name : approvalDetail.name
					}}<el-tag :type="_returnType()" style="margin: 0 10px" size="small">{{
						type == 'againApply' ? approvalDetail2.approvalStatusName : type ? approvalStatusName : '面试通过'
					}}</el-tag>
				</h2>
				<p>
					<span
						>手机：{{
							type == 'againApply' ? approvalDetail2.phoneNumber : type ? approvalDetail3.phoneNumber : approvalDetail.phoneNumber
						}}</span
					>
				</p>
				<p>
					<span>邮箱：{{ type == 'againApply' ? approvalDetail2.mailbox : type ? approvalDetail3.mailbox : approvalDetail.mailbox }}</span>
				</p>
			</div>
		</div>
		<div class="picbox" style="padding-bottom: 30px">
			<el-form ref="form" :model="form" style="width: 90%" label-width="100px">
				<el-row style="margin: 50px 0">
					<div>
						<div class="picbox_div" style="float: left">
							<span class="picbox_div_span">面试岗位</span
							>{{ type == 'againApply' ? approvalDetail2.jobName : type ? approvalDetail3.jobName : approvalDetail.jobName }}
						</div>
						<!-- <div class="picbox_div" style="float: right;">
                    <span class="picbox_div_span">入职时间</span>2019-10-12
                  </div> -->
					</div>
				</el-row>
				<el-row v-if="!type">
					<el-form-item label="申请原因" :rules="{ required: true }">
						<el-input
							v-model="form.reason"
							:autosize="{ minRows: 4, maxRows: 4 }"
							type="textarea"
							style="width: 80%"
							minlength="20"
							maxlength="100"
							show-word-limit
						>
						</el-input>
					</el-form-item>
				</el-row>
				<el-row v-if="!type">
					<el-form-item label="审批人" :rules="{ required: true }">
						<el-tag class="tag-gap" closable @close="removeStaff(i)" v-for="(tag, i) in selectedStaffs" :key="tag.id">{{
							tag.name
						}}</el-tag>
						<departments btnText="添加审批人" @updateStaffTag="addApprovalStaffs" :show-checkbox="false" :show-staff="true"></departments>
					</el-form-item>
				</el-row>
				<div class="picbox_pobox" v-else-if="type && type != 'againApply'">
					<span class="picbox_pobox_title">申请流程</span>
					<el-row style="margin-left: 100px">
						<el-timeline :reverse="true" style="padding-left: 160px">
							<el-timeline-item
								:timestamp="item.time"
								:hide-timestamp="true"
								:color="Number(sort) >= item.sort ? '#409EFF' : ''"
								style="position: relative"
								v-for="(item, index) in approvalDetail2.approvalProcessList"
								:key="index"
							>
								<span style="position: absolute; top: 3px; left: -150px">{{ item.time }}</span>
								<div style="overflow: hidden">
									<el-row style="margin-bottom: 20px">
										<el-col :span="12">
											{{ item.userName }}<span>({{ item.officeName }})</span>
											<el-tag
												:type="item.approvalStatus == 1 ? 'success' : item.approvalStatus == 2 ? 'danger' : 'warning'"
												size="small"
												style="margin-left: 10px"
												>{{ item.approvalStatusName }}</el-tag
											>
										</el-col>
										<!--<el-col :span="12" style="text-align: right;">
                            {{item.time}}
                          </el-col>!-->
									</el-row>
									<el-row style="margin-bottom: 20px">
										<el-col :span="24" style="line-height: 32px">
											<span>意见：{{ item.approvalSuggestion }}</span>
										</el-col>
									</el-row>
									<!-- <p><span>意见：{{item.approvalSuggestion}}</span><el-tag :type="item.approvalStatus == 1 ? 'success' : item.approvalStatus == 2 ? 'danger': 'warning'" style="float: right;">{{item.approvalStatusName}}</el-tag></p> -->
									<div class="docContainer" style="width: 100%" v-for="(file, i) in makeFileList(item.fileUrl)" :key="i">
										<div class="docContent">
											<img src="/static/images/fj.png" style="width: 40px; height: 40px" />
											<div style="width: auto">
												<span class="fileName">{{ file.name }}</span>
												<div style="text-align: left">
													<el-button
														v-show="isImageFileName(file.url)"
														type="text"
														class="media-print__hidden"
														@click="picPreview(file.url)"
														>预览</el-button
													>
													<a
														v-show="!isImageFileName(file.url)"
														class="media-print__hidden"
														style="color: #409eff; font-size: 14px; margin-right: 10px"
														target="_blank"
														:href="'http://view.officeapps.live.com/op/view.aspx?src=' + file.url"
														>预览</a
													>
													<el-button type="text" class="media-print__hidden" @click="downLoadFile(file.url)"
														>下载</el-button
													>
												</div>
											</div>
										</div>
									</div>
								</div>
							</el-timeline-item>
						</el-timeline>
					</el-row>
				</div>
				<div class="picbox_pobox" v-else>
					<span class="picbox_pobox_title">申请流程</span>
					<el-row style="margin-left: 100px">
						<el-timeline :reverse="true" style="padding-left: 160px">
							<el-timeline-item
								:timestamp="item.time"
								:hide-timestamp="true"
								:color="Number(sort) >= item.sort ? '#409EFF' : ''"
								style="position: relative"
								v-for="(item, index) in approvalDetail2.entryApprovalFlowResponseList"
								:key="index"
							>
								<span style="position: absolute; top: 3px; left: -150px">{{ item.approvalTime }}</span>
								<div style="overflow: hidden">
									<el-row style="margin-bottom: 20px">
										<el-col :span="12">
											{{ item.name }}<span>({{ item.jobName }})</span>
											<el-tag
												:type="item.status == 1 ? 'success' : item.status == 2 ? 'danger' : 'primary'"
												size="small"
												style="margin-left: 10px"
												>{{ item.statusName }}</el-tag
											>
										</el-col>
										<!--<el-col :span="12" style="text-align: right;">
                            {{item.time}}
                          </el-col>!-->
									</el-row>
									<el-row style="margin-bottom: 20px" v-show="item.reason">
										<el-col :span="24" style="line-height: 32px">
											<span>意见：{{ item.reason }}</span>
										</el-col>
									</el-row>
								</div>
							</el-timeline-item>
						</el-timeline>
					</el-row>
				</div>
				<el-row v-if="!type">
					<el-col :offset="11" style="margin-top: 10px">
						<el-button @click="$router.go(-1)">取消</el-button>
						<el-button type="primary" @click="handleClick">提交</el-button>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<el-dialog :visible.sync="dialogVisible2">
			<img width="100%" :src="dialogImageUrl" alt="" />
		</el-dialog>
	</div>
</template>
<script>
import { recruitEntryApproval, recruitResumeDetail, resumeDetail, recruitEntryAgainDetail } from '@/api/recruitment_allocation';
import { getApprovalDetails } from '@/api/staffSystem';
import Departments from '@/components/departs';
import { isImageFileName } from '@/utils/util.js';
export default {
	components: { Departments },
	data() {
		return {
			loading: false,
			form: {
				resumeId: this.$route.query.id,
				reason: '',
				staffIds: []
			},
			form2: {
				id: this.$route.query.approvalMainProcessId,
				queryType: '1',
				approvalTypeName: '入职'
			},
			approvalId: this.$route.query.id,
			type: this.$route.query.type,
			approvalDetail: '',
			approvalDetail2: '',
			approvalDetail3: '',
			manPowerApprovalStatusList: [],
			processStatusList: [],
			approvalStatusList: [],
			approvalProcessStatusList: [],
			selectedStaffs: [],
			dialogImageUrl: '',
			dialogVisible2: false,
			approvalStatus: this.$route.query.approvalStatus,
			sort: '',
			approvalStatusName: this.$route.query.approvalStatusName
		};
	},
	mounted() {
		const mapArr = [
			{ name: 'manPowerApprovalStatusList', groupId: 'ManPowerApprovalStatus' },
			{ name: 'processStatusList', groupId: 'processStatus' },
			{ name: 'approvalStatusList', groupId: 'approvalStatus' },
			{ name: 'approvalProcessStatusList', groupId: 'approvalProcessStatus' }
		];

		mapArr.forEach((val) => {
			this[val.name] = this.$store.state.app.dict.filter((item) => item.groupId == val.groupId);
		});
		if (this.type && this.type != 'againApply') {
			this._getApprovalDetails();
			resumeDetail({ id: this.$route.query.id }).then((res) => {
				this.approvalDetail3 = res;
			});
		} else if (this.type == 'againApply') {
			recruitEntryAgainDetail({ resumeId: this.$route.query.id }).then((res) => {
				this.approvalStatus = res.approvalStatus;
				this.sort = res.sort;
				this.approvalDetail2 = res;
			});
		} else {
			recruitResumeDetail({ id: this.approvalId }).then((res) => {
				this.approvalDetail = res;
			});
		}
	},
	methods: {
		handleClick() {
			this.form.staffIds = this.selectedStaffs.map((v) => v.id);
			if (this.selectedStaffs.length <= 0) {
				return this.$message.error('至少添加一个审批人');
			}
			const params = this.form;

			recruitEntryApproval(params).then((res) => {
				if (res.code == 0) {
					this.$message.success('操作成功');
					this.$router.back();
				}
			});
		},

		addApprovalStaffs(staffs) {
			this.selectedStaffs = this.selectedStaffs.concat(staffs);
		},
		removeStaff(index) {
			this.selectedStaffs.splice(index, 1);
		},
		returnStatus() {
			if (this.type) {
				return this.approvalProcessStatusList.find((val) => val.dictId == this.approvalDetail.approvalStatus)['dictName'];
			}
			return '面试通过';
		},
		_returnType() {
			if (this.type && this.type != 'againApply') {
				if (this.approvalStatus == 1) {
					return 'success';
				}
				if (this.approvalStatus == 2) {
					return 'danger';
				}
				return 'primary';
			}
			if (this.type == 'againApply') {
				if (this.approvalStatus == 3) {
					return 'danger';
				}
				if (this.approvalStatus == 2) {
					return 'success';
				}
				return 'primary';
			}
			return 'success';
		},
		makeFileList(json) {
			try {
				const fileList = JSON.parse(json);

				return fileList;
			} catch (err) {
				return [];
			}
		},
		isImageFileName,
		downLoadFile(url) {
			window.open(url);
		},
		picPreview(url) {
			this.dialogImageUrl = url;
			this.dialogVisible2 = true;
		},
		_getApprovalDetails() {
			this.loading = true;
			getApprovalDetails(this.form2).then((res) => {
				this.loading = false;
				if (res._responseStatusCode === 0) {
					this.approvalDetail2 = res;
				}
			});
		}
	}
};
</script>
<style lang="scss">
.applyApprovalInfo {
	.recordBox {
		padding: 20px;
		margin-top: 10px;
		background-color: #fff;
	}
	.titlebox {
		text-align: center;
		line-height: 17px;
		background: #fff;
		font: bold 18px/25px 'Microsoft YaHei';
		padding: 14px 40px;
		margin: 0 0px 20px;
		> .el-button {
			float: left;
			color: #484848;
			transform: translateY(-8px);
			font-size: 16px;
		}
	}
}
.sections-container {
	overflow: hidden;
	margin-bottom: 10px;
	.title-icon {
		font-size: 16px;
		margin: 0 0 20px 30px;
		&:before {
			display: inline-block;
			width: 8px;
			height: 18px;
			background: #498df0;
			content: ' ';
			border-radius: 5px;
			vertical-align: top;
			margin-right: 5px;
		}
	}
}
.sections-container {
	border-radius: 4px;
	padding: 40px 60px;
	background-color: #fff;
}
</style>
<style lang="scss" scoped>
.picbox {
	padding: 0px 0 0 100px;
	background: #fff;
	margin: 0 0 20px;
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	min-height: 140px;
	.picbox_div {
		position: relative;
		.picbox_div_span {
			text-align: right;
			color: #606266;
			width: 100px;
			padding-right: 24px;
			margin-left: 30px;
			font-size: 14px;
		}
	}
	.picbox_img {
		display: inline-block;
		border-radius: 50%;
		height: 100px;
		width: 100px;
	}
	h2 {
		margin: 10px 0 0 0;
	}

	p {
		margin: 15px 0px;

		color: #999999;
	}
	.picbox_pobox {
		position: relative;
		margin-bottom: 20px;
		.picbox_pobox_title {
			position: absolute;
			font-size: 14px;
			color: #606266;
			width: 100px;
			padding-right: 12px;
			text-align: right;
			left: 0;
		}
	}
	.tag-gap {
		margin-right: 8px;
		margin-bottom: 8px;
	}
}
::v-deep.el-form-item__label {
	font-weight: normal;
}
.docContainer {
	.docContent {
		display: flex;
		align-items: center;
		margin-left: 10px;
		min-width: 120px;
	}
	.fileName {
		// display: block;
		font-size: 13px;
		text-align: left;
		// width: 300px;
		// overflow: hidden;
		// text-overflow: ellipsis;
		// white-space: nowrap;
	}
}
</style>
